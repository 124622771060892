<script setup lang="ts">
import { LockOutlined, UserOutlined } from '@ant-design/icons-vue'
import { delayTimer } from '@v-c/utils'
import { AxiosError } from 'axios'
import { loginApi } from '@/api/login'
import router from '@/router'
import pageBubble from '@/utils/page-bubble'
import bgImage from '@/assets/images/login-left.png'

const userStore = useUserStore()
const cacheConfigStore = useWorkBenchCacheConfigStore()
cacheConfigStore.refreshWorkBenchConfig()
const { project } = storeToRefs(cacheConfigStore)
const bubbleCanvas = ref<HTMLCanvasElement>()
const appStore = useAppStore()
const notification = useNotification()
const { layoutSetting } = storeToRefs(appStore)
const submitLoading = shallowRef(false)
const loginModel = reactive({
  loginName: undefined,
  password: undefined,
  remember: true,
})
const { t } = useI18nLocale()
const formRef = shallowRef()
const errorAlert = shallowRef(false)

async function submit() {
  submitLoading.value = true
  try {
    await formRef.value?.validate()
    const params: LoginParam = { loginName: loginModel.loginName, password: loginModel.password } as unknown as LoginParam

    await loginApi(params)
    await userStore.refreshUserInfo()

    notification.success({ message: '登录成功', description: '欢迎回来', duration: 3 })
    // 获取当前是否存在重定向的链接，如果存在就走重定向的地址
    // const redirect = getQueryParam('redirect', '/workbench')
    router.push({ path: '/workbench', replace: true })
  }
  catch (error) {
    if (error instanceof AxiosError)
      errorAlert.value = true
    submitLoading.value = false
  }
}

onMounted(async () => {
  await delayTimer(300)
  pageBubble.init(unref(bubbleCanvas)!)
})

onBeforeUnmount(() => {
  pageBubble.removeListeners()
})
</script>

<template>
  <div class="login-container">
    <!-- 背景动画 -->
    <div class="h-screen w-screen absolute z-10">
      <canvas ref="bubbleCanvas" />
    </div>
    <div class="login-content flex-center">
      <div class="ant-pro-form-login-main rounded">
        <!-- 登录头部 -->
        <div class="flex-between h-15 px-4 mb-[2px]">
          <div flex items-center>
            <span class="ant-pro-form-login-logo">
              <img w-full h-full object-cover :src="project?.icon" rounded-full>
            </span>
            <span class="ant-pro-form-login-title">
              {{ project?.name ?? '平台名称' }}
            </span>
            <!-- <span class="ant-pro-form-login-desc">
              {{ t("pages.layouts.userLayout.title") }}
            </span> -->
          </div>
          <div v-if="false" class="login-lang flex-center relative z-11">
            <span
              class="flex-center cursor-pointer text-16px"
              @click="appStore.toggleTheme(layoutSetting.theme === 'dark' ? 'light' : 'dark')"
            >
              <!-- 亮色和暗黑模式切换按钮 -->
              <template v-if="layoutSetting.theme === 'light'">
                <carbon-moon />
              </template>
              <template v-else>
                <carbon-sun />
              </template>
            </span>
            <!-- <SelectLang /> -->
          </div>
        </div>
        <!-- 划分线 -->
        <a-divider m-0 />
        <!-- 登录主体 -->
        <div class="box-border flex min-h-[520px]">
          <!-- 登录框左侧 -->
          <div class="ant-pro-form-login-main-left min-h-[520px] flex-center  bg-[var(--bg-color-container)]">
            <img :src="`${project?.loginBackgroundImage ? project.loginBackgroundImage : bgImage}`" class="h-5/6 w-5/6">
          </div>
          <a-divider m-0 type="vertical" class="ant-pro-login-divider  min-h-[520px]" />
          <!-- 登录框右侧 -->
          <div class="ant-pro-form-login-main-right px-5 w-[335px] flex-center flex-col relative z-11">
            <div class="text-center py-6 text-2xl">
              {{ t('pages.login.tips') }}
            </div>
            <a-form ref="formRef" :model="loginModel">
              <!-- 判断是否存在error -->
              <a-alert
                v-if="errorAlert" mb-24px :message="t('pages.login.accountLogin.errorMessage')" type="error"
                show-icon
              />
              <a-form-item name="loginName" :rules="[{ required: true, message: t('pages.login.username.required') }]">
                <a-input
                  v-model:value="loginModel.loginName" allow-clear autocomplete="off"
                  :placeholder="t('pages.login.username.placeholder')" size="large" @press-enter="submit"
                >
                  <template #prefix>
                    <UserOutlined />
                  </template>
                </a-input>
              </a-form-item>
              <a-form-item name="password" :rules="[{ required: true, message: t('pages.login.password.required') }]">
                <a-input-password
                  v-model:value="loginModel.password" allow-clear
                  :placeholder="t('pages.login.password.placeholder')" size="large" @press-enter="submit"
                >
                  <template #prefix>
                    <LockOutlined />
                  </template>
                </a-input-password>
              </a-form-item>
              <a-button type="primary" block :loading="submitLoading" size="large" @click="submit">
                <!-- {{ t('pages.login.submit') }} -->
                登录
              </a-button>
            </a-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.login-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  background: var(--bg-color-container);
}

.login-lang {
  height: 40px;
  line-height: 44px;
}

.login-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ant-pro-form-login-container {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  height: 100%;
  padding: 32px 0;
  overflow: auto;
  background: inherit
}

.ant-pro-form-login-header a {
  text-decoration: none
}

.ant-pro-form-login-title {
  color: var(--text-color);
  font-weight: 600;
  font-size: 33px;
  line-height: 1;
}

.ant-pro-form-login-logo {
  width: 44px;
  height: 44px;
  margin-right: 16px;
  vertical-align: top
}

.ant-pro-form-login-desc {
  color: var(--text-color-1);
  font-size: 14px;
  margin-left: 16px
}

.ant-pro-form-login-main-right {
  .ant-tabs-nav-list {
    margin: 0 auto;
    font-size: 16px;
  }

  .ant-pro-form-login-other {
    line-height: 22px;
    text-align: center
  }

}

.ant-pro-form-login-main-left {
  width: 700px;
}

.ant-pro-form-login-main {
  box-shadow: var(--c-shadow);
}

.icon {
  margin-left: 8px;
  color: var(--text-color-2);
  font-size: 24px;
  vertical-align: middle;
  cursor: pointer;
  transition: color .3s;

  &:hover {
    color: var(--pro-ant-color-primary);
  }
}
</style>
